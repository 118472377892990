<template>
  <div
    style="
      text-align: right;
      font-size: 16px;
      font-family: DIN;
      font-weight: 500;
      color: #25e3ff;
    "
  >
    {{ dateFormat }}
  </div>
</template>

<script>
import moment from 'moment';
import { momentWeek } from '../config';
export default {
  //组件注册
  components: {},
  //组件传值
  props: {},
  data() {
    return {
      timer: null,
      date: Date.now(),
    };
  },
  //计算属性
  computed: {
    dateFormat() {
      const entity = moment(this.date);
      return `${entity.format('HH:mm:ss  YYYY-MM-DD')}  星期${
        momentWeek[entity.format('d')] ?? ''
      }`;
    },
  },
  //属性监听
  watch: {},
  //DOM访问
  mounted() {
    this.timer = setInterval(() => {
      this.date = Date.now();
    }, 0);
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  //保存方法
  methods: {},
};
</script>

<style lang="scss" scoped></style>
